<template>
  <div>
    <b-card
      class="page-card"
      header-class="page-card-with-logo page-card-header"
      body-class="page-card-body"
    >
      <div slot="header">
        <b-row class="fit-to-center">
          <b-col cols="6">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-spreadsheet card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Reporter</b> - <i>Select Peer Group</i>
                </h5>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="page-header-right">
              <header-logo
                logoType="fi"
                :logoUrl="s3LogoUrl"
                :logoId="selectedInstitution.id"
              ></header-logo>
              <div>
                <h5 class="mb-0">{{ selectedInstitution.name }}</h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row class="search-container--peer-group">
        <b-col sm="12">
          <div class="search-container--bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                placeholder="Type Peer Group to Select"
                @keyup.native="search"
                v-model="searchText"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <div class="search-container--result std-border">
            <v-wait for="peersLoading">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <div class="text-left">
                <b-list-group v-if="emptyPeerGroup" class="text-center">
                  <b-list-group-item
                    ><i>No Peer Groups found ... </i></b-list-group-item
                  >
                </b-list-group>
                <b-list-group v-else>
                  <div
                    v-for="(peerGroup, index) in filteredPeerGroups"
                    :key="index"
                  >
                    <b-list-group-item
                      @click="setPeerGroup(peerGroup)"
                      :class="[
                        { selected: peerGroup.id === selected_peer_group },
                        'p-2',
                        'pl-4'
                      ]"
                      :title="`Report ID: ${peerGroup.report_id || 'NA'}`"
                    >
                      {{ peerGroup.name }}
                      <span class="text-muted" v-if="peerGroup.common_peer">
                        <b-badge pill variant="secondary" class="px-1">
                          Global
                        </b-badge>
                      </span>
                      <span
                        v-if="peerGroup.creator_id"
                        class="manage--peer-group"
                      >
                        <button
                          type="submit"
                          class="float-right btn btn-secondary btn-sm mx-1"
                          @click.stop="deletePeerGroup(peerGroup)"
                        >
                          Delete
                        </button>
                        <button
                          type="submit"
                          class="float-right btn btn-primary btn-sm mx-1"
                          @click.stop="sharePeerGroup(peerGroup)"
                        >
                          Share
                        </button>
                        <button
                          type="submit"
                          class="float-right btn btn-primary btn-sm mx-1"
                          @click.stop="editPeerGroup(peerGroup)"
                        >
                          Edit
                        </button>
                        <button
                          type="submit"
                          class="float-right btn btn-common btn-primary btn-sm mx-1"
                          @click.stop="commonizePeerGroup(peerGroup, true)"
                          v-if="!peerGroup.common_peer"
                        >
                          Set as Global
                        </button>
                        <button
                          type="submit"
                          class="float-right btn btn-common-remove btn-primary btn-sm mx-1"
                          @click.stop="commonizePeerGroup(peerGroup, false)"
                          v-if="peerGroup.common_peer"
                        >
                          Remove from Global
                        </button>
                      </span>
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </div>
            </v-wait>
          </div>
        </b-col>
      </b-row>

      <b-row class="text-center pt-1">
        <b-col md="12">
          <b-button
            size=""
            variant="primary"
            class="fw-btn mt-3 mx-1"
            @click="showPeerModal('create')"
          >
            Create Peer Group
          </b-button>
          <router-link
            tag="button"
            :to="{ path: 'select_report' }"
            class="btn btn-primary fw-btn mt-3"
            :disabled="!enableContinue"
          >
            Continue
          </router-link>
        </b-col>
      </b-row>

      <b-row v-if="selected_peer_group" class="peer-group--members">
        <b-col md="12">
          <b-card
            class="std-border metrics-card mt-4"
            body-class="p-1 section--pg-members"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0"><b>Peer Group Members</b></h6>
            </div>
            <v-wait for="membersLoading">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <b-list-group-item
                  v-for="(bank, index) in peerGroupBanks"
                  :key="index"
                  class="p-2"
                >
                  {{ bank.name }} - {{ bank.city }}, {{ bank.state }}
                </b-list-group-item>
              </b-list-group>
            </v-wait>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <CustomPeerGroup
      :selectedFI="selectedInstitution"
      :peerGroupId="selected_peer_group"
      :showModal="showCustomPeerModal"
      :type="peerModalType"
      @peerGroupCreated="reloadPeerGroups"
      @peerGroupUpdated="peerGroupUpdate"
      @closeModal="closeModal"
    ></CustomPeerGroup>
    <share-peer-group :peerGroup="selectedPeerGroup"></share-peer-group>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import store from '@/store/store'
import router from '@/router'
import errorHandle from '@/services/error_handle'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import CustomPeerGroup from '@/modules/core/components/CustomPeerGroup'
import SharePeerGroup from '@/modules/core/components/SharePeerGroup'

export default {
  name: 'PeerGroup',
  components: {
    HeaderLogo,
    CustomPeerGroup,
    SharePeerGroup
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['selectedInstitution'].id) {
      next()
    } else {
      Vue.toasted.show('Please select institution.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/analytics/reporter/select_institution')
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Reporter'
    }
    this.fetchPeerGroupData()
    this.fetchUserGroups()
  },
  data() {
    return {
      searchText: '',
      filteredPeerGroups: [],
      editable: false,
      peerGroupBanks: [],
      peerModalType: 'create',
      showCustomPeerModal: false
    }
  },
  computed: {
    ...mapState(['partnerSite']),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapGetters([
      'selectedInstitution',
      'selected_peer_group',
      'peer_groups',
      's3LogoUrl'
    ]),
    resultHeight() {
      return window.innerHeight - 300
    },
    enableContinue() {
      return this.selected_peer_group !== null
    },
    enableEdit() {
      return this.selected_peer_group && this.editable
    },
    emptyPeerGroup() {
      return this.peer_groups.length === 0
    },
    selectedPeerGroup() {
      return (
        this.peer_groups.find(pg => pg.id === this.selected_peer_group) || {}
      )
    }
  },
  methods: {
    ...mapActions('Authentication', ['fetchUserGroups']),
    fetchPeerGroupData() {
      if (this.selectedInstitution.id) {
        this.$wait.start('peersLoading')
        this.$http
          .get('/api/peer_groups/?bank_id=' + this.selectedInstitution.id, {
            handleErrors: false
          })
          .then(
            res => {
              let defaultPeerGroups = res.data.peer_groups.filter(
                pg => pg.creator_id === null
              )
              let userPeerGroups = res.data.peer_groups.filter(
                pg => pg.creator_id !== null
              )
              let sortedPeerGroups = defaultPeerGroups.concat(userPeerGroups)
              this.$store.commit('peer_groups', sortedPeerGroups)
              this.filteredPeerGroups = sortedPeerGroups
              this.$wait.end('peersLoading')
            },
            error => {
              errorHandle.handleError(this, error)
            }
          )
        this.fetchPeerGroupBanks()
      }
    },
    search: function(event) {
      let text = event.target.value.toLowerCase()
      if (this.text === '') {
        this.filteredPeerGroups = this.peer_groups
      } else {
        this.filteredPeerGroups = _.filter(this.peer_groups, peerGroup => {
          return peerGroup.name.toLowerCase().indexOf(text) >= 0
        })
      }
    },
    setPeerGroup: function(peerGroup) {
      this.editable = peerGroup.creator_id !== null
      this.searchText = peerGroup.name
      this.$store.commit('setPeerGroup', peerGroup.id)
      this.fetchPeerGroupBanks()
    },
    fetchPeerGroupBanks() {
      if (this.selected_peer_group) {
        this.$wait.start('membersLoading')
        this.$http
          .get(`/api/peer_groups/${this.selected_peer_group}/banks`, {
            handleErrors: true
          })
          .then(res => {
            this.peerGroupBanks = res.data.banks
          })
          .then(() => {
            this.$wait.end('membersLoading')
          })
      }
    },
    editPeerGroup(peerGroup) {
      this.$store.commit('setPeerGroup', peerGroup.id)
      this.peerModalType = 'edit'
      this.showCustomPeerModal = true
    },
    sharePeerGroup(peerGroup) {
      this.$store.commit('setPeerGroup', peerGroup.id)
      this.$nextTick(() => {
        this.$bvModal.show('peer-group-share')
      })
    },
    deletePeerGroup: function(peerGroup) {
      this.$http
        .delete('/api/peer_groups/' + peerGroup.id, {
          handleErrors: false
        })
        .then(
          () => {
            this.peer_groups.splice(
              this.peer_groups.map(x => x.id).indexOf(peerGroup.id),
              1
            )
            if (peerGroup.id === this.selected_peer_group.id) {
              this.$store.commit('setPeerGroup', {})
            }
          },
          error => {
            errorHandle.handleActionError(
              this,
              error,
              'You are not allowed to delete this Peer group.'
            )
          }
        )
    },
    commonizePeerGroup: function(peerGroup, commonPeer) {
      this.$http
        .post(
          `/api/peer_groups/${peerGroup.id}/update_common_peer`,
          { common_peer: commonPeer },
          {
            handleErrors: false
          }
        )
        .then(
          () => {
            peerGroup.common_peer = commonPeer
            let msg = commonPeer
              ? 'Peer Group successfully set as Global Peer Group.'
              : 'Unset Global Peer Group successful.'
            Vue.toasted.show(msg, {
              icon: 'user-circle',
              type: 'success'
            })
          },
          error => {
            errorHandle.handleActionError(
              this,
              error,
              'You are not allowed to delete this Peer group.'
            )
          }
        )
    },
    reloadPeerGroups() {
      this.fetchPeerGroupData()
    },
    peerGroupUpdate() {
      this.$store.commit('setPeerGroup', {})
      this.peerGroupBanks = []
      this.reloadPeerGroups()
    },
    showPeerModal(type) {
      this.peerModalType = type
      this.showCustomPeerModal = true
    },
    closeModal() {
      this.showCustomPeerModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.section--pg-members {
  height: 20rem;
  overflow: auto;
}

.manage--peer-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
  .btn-common {
    width: 7rem;
  }
  .btn-common-remove {
    width: 9rem;
  }
}

.list-group-item {
  &:hover {
    .manage--peer-group {
      display: inline;
    }
  }
}
</style>
